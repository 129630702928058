<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >
    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>{{ $t('ENTRIES') }}</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
        </b-col>
        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('SEARCH')"
            />
          </div>
        </b-col>
      </b-row>

    </div>
    <b-table
      ref="refUserSurveyListTable"
      :items="allSurveys"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      :empty-text="$t('NO_MATCHING_RECORDS_FOUND')"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >
      <!-- Column: ID -->
      <!-- Column:SurveyTitle -->
      <template #cell(Name)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ strSubstring(data.item.name,20) }}
          </span>
        </b-media>
      </template>
      <!-- Column:Reference ID -->
      <template #cell(ReferenceID)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.reference_id }}
          </span>
        </b-media>
      </template>
      <!-- Column:StartDate -->
      <template #cell(StartDate)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ formatDate(data.item.start_date) }}
          </span>
        </b-media>
      </template>
      <!-- Column:EndDate -->
      <template #cell(EndDate)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ formatDate(data.item.end_date) }}
          </span>
        </b-media>
      </template>
      <!-- Column:TotalUsers -->
      <template #cell(TotalUsers)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.total_users }}
          </span>
        </b-media>
      </template>

      <!-- Column:TotalResponses -->
      <template #cell(TotalResponses)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.total_responses }}
          </span>
        </b-media>
      </template>

      <!-- Column:Status -->
      <template #cell(Status)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.survey_status }}
          </span>
        </b-media>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <feather-icon
            v-show="isBeforeToday(data.item.end_date)"
            :id="`game-view-${data.item.ID}-send-icon`"
            icon="Link2Icon"
            class="cursor-pointer mr-1"
            size="16"
            @click="navigateSurveyPage(data.item)"
          />
          <feather-icon
            v-show="data.item.total_responses>0"
            :id="`game-view-${data.item.ID}-send-icon`"
            icon="BarChart2Icon"
            class="cursor-pointer mr-1"
            size="16"
            @click="$router.push({ name: 'survey-view-id', params: { id: data.item.ID }})"
          />
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('SHOWING') }} {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} {{ $t('ENTRIES') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalSurveys"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow, BCol, BFormInput, BTable,
  BMedia,
  BPagination, VBPopover,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapState } from 'vuex'
import { ref, computed } from '@vue/composition-api'
import { mixinList } from '@/constants/mixinValidations'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { mixinDate } from '@/constants/mixinDate'
import constants from '@/constants/static.json'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
import { isBeforeToday } from '@/constants/utils'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BMedia,
    BPagination,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    VBPopover,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  mixins: [mixinList, mixinDate],
  setup() {
    const tableColumns = [
      { key: 'Name', sortable: false },
      { key: 'ReferenceID', sortable: false },
      { key: 'StartDate', sortable: false },
      { key: 'EndDate', sortable: false },
      { key: 'TotalResponses', sortable: false },
      { key: 'Status', sortable: false },
      { key: 'Actions' },
    ]

    const { PER_PAGE_OPTIONS, PER_PAGE } = constants
    const refUserSurveyListTable = ref(null)

    const perPage = ref(PER_PAGE)
    const totalSurveys = ref(0)
    const currentPage = ref(1)
    const perPageOptions = PER_PAGE_OPTIONS
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const statusFilter = ref('')

    const dataMeta = computed(() => {
      const localItemsCount = refUserSurveyListTable.value ? refUserSurveyListTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalSurveys.value,
      }
    })

    const refetchData = () => {
      refUserSurveyListTable.value.refresh()
    }

    const {
      successMessage,
      errorMessage,
      showErrorMessage,
      warningMessage,
    } = mixinAlert()

    return {
      tableColumns,
      perPage,
      isBeforeToday,
      currentPage,
      totalSurveys,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserSurveyListTable,
      statusFilter,
      refetchData,
      successMessage,
      errorMessage,
      showErrorMessage,
      warningMessage,
    }
  },
  created() {
    this.fetchSurveys()
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapState('survey', ['allSurveys', 'surveyCount']),
  },
  // eslint-disable-next-line vue/order-in-components
  watch: {
    currentPage: {
      handler() {
        this.fetchSurveys()
      },
    },
  },
  methods: {
    ...mapActions('survey', ['fetchAllSurveys']),
    navigateSurveyPage(data) {
      let link = `${process.env.VUE_APP_HOST}bisevo/survey/${data.encryptID}?user=${data.userEncryptID}`
      if (data.survey_status === constants.SURVEY_STATUS[1]) {
        link = `${process.env.VUE_APP_HOST}bisevo/survey-results/${data.encryptID}`
      }
      window.open(link, '_blank')
    },
    fetchSurveys() {
      const payload = {
        q: this.searchQuery,
        perPage: this.perPage,
        page: this.currentPage,
        sortBy: this.sortBy,
        sortDesc: this.isSortDirDesc,
        status: this.statusFilter,
      }
      try {
        this.fetchAllSurveys(payload).then(() => {
          this.totalSurveys = this.surveyCount
        })
      } catch (error) {
        this.showErrorMessage()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
